import React, { useState, useEffect } from "react";
import Header from "../parts/Header";
import loginStyles from "../../styles/login.module.scss";
import welcomeImg from "../../resources/images/welcome_img.png";
import twitterLoginButton from "../../resources/images/twitter_login.png";
import {
  CONTACT_FORM,
  DIALOG_MODE,
  STANDARD_WIDTH,
  TABLE_NAME,
} from "../../resources/constants";
import { BUTTON_TEXT, ABOUT_APP, APP_NAME, NOTES } from "../../resources/messages";
import PopUpDialog from "../parts/PopUpDialog";
import {
  signInWithRedirect,
  TwitterAuthProvider,
  onAuthStateChanged,
} from "firebase/auth";
import { doc, updateDoc } from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import useMediaQuery from "@mui/material/useMediaQuery";
import { auth, db } from "../../config/firebase";
import { updateProfile } from "firebase/auth";

const Login = () => {
  const [dialogMode, setDialogMode] = useState("");
  const [isOpenDialog, setIsOpenDialog] = useState(false);
  const [isReady, setIsReady] = useState(false);

  const navigate = useNavigate();
  const isStandardWidth = useMediaQuery(STANDARD_WIDTH);

  const popUpDetail = () => {
    setIsOpenDialog(true);
    setDialogMode(DIALOG_MODE.SHOW_DETAIL);
  };

  const popUpTermsOfUse = () => {
    setIsOpenDialog(true);
    setDialogMode(DIALOG_MODE.TERMS_OF_USE);
  };

  const navigateContact = () => {
    window.open(CONTACT_FORM);
  };

  const handleCloseDialog = () => {
    setIsOpenDialog(false);
    setDialogMode("");
  };

  // すでにログイン済みの場合、ログインページをスキップする
  useEffect(() => {
    document.fonts
      .load("12px a-otf-ud-shin-maru-go-pr6n")
      .then(() => setIsReady(true));
    const getUser = onAuthStateChanged(auth, async (user) => {
      if (user) {
        const userId = user.reloadUserInfo.screenName;
        const userDocumentRef = doc(db, TABLE_NAME.USERS, userId);
        const avatar = auth.currentUser.providerData[0].photoURL.replace(
          "normal",
          "200x200"
        );
        // Twitterのアイコンが変わっていたら、SUKIDANAアプリのアイコンも変える
        // 画像変更機能が実装されることになったら、Twitterアイコンを使用している人だけここを通るように修正する
        if (avatar !== user.photoURL) {
          // Firebase AuthenticationのphotoURLを更新する
          updateProfile(auth.currentUser, { photoURL: avatar });
          // DBのユーザ情報を更新する
          updateDoc(userDocumentRef, {
            avatar: avatar,
          });
        }
        // ユーザページに遷移する
        const navigateLink = userId ? `/${userId}/1` : "/";
        navigate(navigateLink);
      }
    });
    return () => {
      getUser();
    };
  }, [navigate]);

  const twitterLogin = () => {
    const provider = new TwitterAuthProvider();
    signInWithRedirect(auth, provider);
  };

  const handleLogin = () => {
    twitterLogin();
    navigate("/callback/login");
  };

  return (
    isReady && (
      <div
        className={
          isStandardWidth ? loginStyles.login : loginStyles.loginNarrow
        }
      >
        <Header isAuth={false} isStandardWidth={isStandardWidth} />
        <div className={loginStyles.background}>
          <img className={loginStyles.welcomeImg} src={welcomeImg} alt="" />
        </div>
        {/* アプリの概要を表示させるところの帯 */}
        <div className={loginStyles.textBand}></div>
        <div
          className={
            isStandardWidth
              ? loginStyles.explainText
              : loginStyles.explainTextNarrow
          }
        >
          <div className={loginStyles.appName}>{APP_NAME}</div>
          <div className={loginStyles.aboutApp}>{ABOUT_APP}</div>
        </div>
        <button className={loginStyles.detailButton} onClick={popUpDetail}>
          <span>{BUTTON_TEXT.DETAIL_APP}</span>
        </button>
        <button className={loginStyles.contactButton} onClick={navigateContact}>
          <span>{BUTTON_TEXT.CONTACT}</span>
        </button>
        <button className={loginStyles.termOfUseButton} onClick={popUpTermsOfUse}>
          <span>{BUTTON_TEXT.TERMS_OF_USE}</span>
        </button>
        <button
          className={
            isStandardWidth
              ? loginStyles.twitterLogin
              : loginStyles.twitterLoginMobile
          }
          onClick={handleLogin}
        >
          <img src={twitterLoginButton} alt="Twitterログイン" />
        </button>
        <div
          className={
            isStandardWidth
              ? loginStyles.loginNote
              : loginStyles.loginNoteMobile
          }
        >
          {isStandardWidth ? NOTES.LOGIN_NOTE : NOTES.LOGIN_NOTE_MOBILE}
        </div>
        <PopUpDialog
          isOpen={isOpenDialog}
          dialogMode={dialogMode}
          isStandardWidth={isStandardWidth}
          handleClose={handleCloseDialog}
        />
      </div>
    )
  );
};
export default Login;
