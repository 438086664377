import React from "react";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

const ProgressRing = (props) => {
  return (
    <Backdrop open={props.isLoading}>
      <CircularProgress />
    </Backdrop>
  );
};
export default ProgressRing;
