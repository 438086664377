import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Container from "@mui/material/Container";
import { DIALOG_MODE } from "../../resources/constants";
import MenuButton from "./MenuButton";
import commonStyles from "../../styles/common.module.scss";
import appLogo from "../../resources/images/app_logo.png";

const ResponsiveAppBar = ({
  isAuth,
  isStandardWidth,
  handleOpenDialog,
  handleClickAppLogo,
}) => {
  return (
    <AppBar position="fixed" className={commonStyles.appHeader}>
      <Container
        className={
          isStandardWidth
            ? commonStyles.headerContainer
            : commonStyles.headerContainerMobile
        }
      >
        <Toolbar disableGutters className={commonStyles.headerToolbar}>
          <input
            type="image"
            className={commonStyles.appLogo}
            src={appLogo}
            alt=""
            onClick={handleClickAppLogo}
          />
          {/* 自分の棚を見ている場合のみメニューを表示する */}
          {isAuth && (
            <MenuButton
              openSetSnsDialog={() => handleOpenDialog(DIALOG_MODE.SETTING_SNS)}
              openChangeNameDialog={() =>
                handleOpenDialog(DIALOG_MODE.SETTING_NAME)
              }
              openDeleteAccountDialog={() =>
                handleOpenDialog(DIALOG_MODE.DELETE_ACCOUNT)
              }
            />
          )}
        </Toolbar>
      </Container>
    </AppBar>
  );
};
export default ResponsiveAppBar;
