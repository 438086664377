// Firebaseの設定情報をまとめている
import { APP_MODE } from "../resources/constants";
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";

// Firebaseの接続情報を切り替える関数
const switchFirebaseConfig = () => {
  switch (process.env.REACT_APP_MODE) {
    case APP_MODE.DEVELOPMENT:
      return {
        apiKey: process.env.REACT_APP_FIREBASE_API_KEY_DEV,
        authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN_DEV,
        projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID_DEV,
      };
    case APP_MODE.PRODUCTION:
      return {
        apiKey: process.env.REACT_APP_FIREBASE_API_KEY_PRD,
        authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN_PRD,
        projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID_PRD,
      };
    default:
      return {};
  }
};

const firebaseConfig = switchFirebaseConfig();

initializeApp(firebaseConfig);

// アプリで使用する認証情報とDBの情報をエクスポートしていろんなところで使えるようにする
export const auth = getAuth();

export const db = getFirestore();
