import React, { useState } from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import menuIcon from "../../resources/images/menu.png";
import { MENU_TEXT } from "../../resources/messages";
import { auth } from "../../config/firebase";
import { useNavigate } from "react-router-dom";
import commonStyles from "../../styles/common.module.scss";
import { CONTACT_FORM } from "../../resources/constants";

const MenuButton = (props) => {
  // メニューを出現させる場所
  const [anchorEl, setAnchorEl] = useState(null);
  const navigate = useNavigate();
  const isOpen = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  // const openSetSnsDialog = () => {
  //   handleClose();
  //   props.openSetSnsDialog();
  // };

  const handleSignOut = () => {
    handleClose();
    auth.signOut().then(() => navigate("/"));
  };

  const openChangeNameDialog = () => {
    handleClose();
    props.openChangeNameDialog();
  };

  const openDeleteAccountDialog = () => {
    handleClose();
    props.openDeleteAccountDialog();
  };

  const navigateContact = () => {
    window.open(CONTACT_FORM);
  };

  return (
    <div>
      <input
        className={commonStyles.menuButton}
        type="image"
        src={menuIcon}
        alt="メニュー"
        onClick={handleClick}
      />
      <Menu
        PaperProps={{
          className: commonStyles.menuBox,
        }}
        anchorEl={anchorEl}
        open={isOpen}
        onClose={handleClose}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <MenuItem
          className={commonStyles.menuItem}
          disableRipple
          onClick={handleSignOut}
        >
          {MENU_TEXT.SIGN_OUT}
        </MenuItem>
        <MenuItem
          className={commonStyles.menuItem}
          disableRipple
          onClick={openChangeNameDialog}
        >
          {MENU_TEXT.CHANGE_PROFILE}
        </MenuItem>
        <MenuItem
          className={commonStyles.menuItem}
          disableRipple
          onClick={navigateContact}
        >
          {MENU_TEXT.CONTACT}
        </MenuItem>
        <MenuItem
          className={commonStyles.menuDeleteAccount}
          disableRipple
          onClick={openDeleteAccountDialog}
        >
          {MENU_TEXT.DELETE_ACCOUNT}
        </MenuItem>
      </Menu>
    </div>
  );
};

export default MenuButton;
