import React, { useState, useEffect } from "react";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import { DIALOG_MODE } from "../../resources/constants";
import {
  APP_NAME,
  BUTTON_TEXT,
  COPY_TO_CLIPBOARD,
  DETAIL_APP,
  DIALOG_TITLE,
  ERROR_MESSAGES,
  TERMS_OF_USE,
} from "../../resources/messages";
import commonStyles from "../../styles/common.module.scss";
import copyButton from "../../resources/images/copy_button.png";
import tweetButton from "../../resources/images/tweet_button.png";
import { TwitterShareButton } from "react-share";

const PopUpDialog = (props) => {
  const [message, setMessage] = useState("");

  const shareUrl = window.location.href;

  const handleClose = () => {
    props.handleClose();
  };

  const handleCopyToClipboard = () => {
    navigator.clipboard
      .writeText(shareUrl)
      .then(() => {
        alert(COPY_TO_CLIPBOARD);
        props.handleClose();
      })
      .catch(() => {
        alert(ERROR_MESSAGES.COPY_TO_CLIPBOARD_FAILED);
      });
  };

  useEffect(() => {
    switch (props.dialogMode) {
      case DIALOG_MODE.ERROR:
        setMessage(props.errorMessage);
        break;
      case DIALOG_MODE.SHOW_DETAIL:
        setMessage(DETAIL_APP);
        break;
      case DIALOG_MODE.SHARE:
        setMessage(DIALOG_TITLE.SHARE);
        break;
      case DIALOG_MODE.TERMS_OF_USE:
        setMessage(TERMS_OF_USE);
        break;
      default:
        break;
    }
  }, [props.dialogMode, props.errorMessage]);

  return (
    <Dialog
      className={
        props.isStandardWidth
          ? commonStyles.modalDialog
          : commonStyles.modalDialogMobile
      }
      open={props.isOpen}
      onClose={handleClose}
      // ダイアログのボックス（白いハコ）のスタイルを上書きするためこのように書いている
      PaperProps={{
        className: props.isStandardWidth
          ? commonStyles.dialogPaper
          : commonStyles.dialogPaperMobile,
      }}
    >
      <DialogTitle
        className={
          props.dialogMode === DIALOG_MODE.SHOW_DETAIL
            ? commonStyles.dialogTitleWithWebFonts
            : commonStyles.dialogTitle
        }
      >
        {/* リンク共有の際はダイアログのタイトルにメッセージを出す */}
        {props.dialogMode === DIALOG_MODE.SHARE && message}
        {props.dialogMode === DIALOG_MODE.SHOW_DETAIL && (
          <div>
            {APP_NAME}
            <span>について</span>
          </div>
        )}
      </DialogTitle>
      <DialogContent
        className={
          props.dialogMode === DIALOG_MODE.SHOW_DETAIL
            ? commonStyles.dialogTextWithWebFonts
            : props.dialogMode === DIALOG_MODE.TERMS_OF_USE
              ? commonStyles.dialogTextTermOfUse
              : commonStyles.dialogText
        }
      >
        {props.dialogMode !== DIALOG_MODE.SHARE && message}
        {props.dialogMode === DIALOG_MODE.SHARE && (
          <div>
            <TwitterShareButton
              title={
                props.userName
                  ? `${props.userName}の「好き」棚\n#スキダナ #SUKIDANA\n`
                  : "SUKIDANA\n#スキダナ #SUKIDANA\n"
              }
              url={shareUrl}
            >
              <img
                className={
                  props.isStandardWidth
                    ? commonStyles.tweetButton
                    : commonStyles.tweetButtonMobile
                }
                src={tweetButton}
                alt="ツイート"
              />
            </TwitterShareButton>
            <div
              className={
                props.isStandardWidth
                  ? commonStyles.copyBar
                  : commonStyles.copyBarMobile
              }
            >
              <input
                type="text"
                className={commonStyles.inputText}
                placeholder={shareUrl}
                readOnly={true}
              />
              <input
                type="image"
                className={commonStyles.copyButton}
                src={copyButton}
                alt="コピー"
                onClick={handleCopyToClipboard}
              />
            </div>
          </div>
        )}
      </DialogContent>
      <DialogActions className={commonStyles.dialogActions}>
        {props.dialogMode !== DIALOG_MODE.SHARE && (
          <button
            className={commonStyles.actionButton}
            onClick={props.handleClose}
          >
            {BUTTON_TEXT.OK}
          </button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default PopUpDialog;
