import "./styles/App.scss";
import Callback from "./components/pages/Callback";
import Home from "./components/pages/Home";
import Login from "./components/pages/Login";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "firebase/auth";
import { AuthProvider } from "./context/auth";
import { DndProvider } from "react-dnd";
import { TouchBackend } from "react-dnd-touch-backend";

// アプリの画面を表示する一番根本的なところ。
// ここのreturn()の中にHTMLをベタ貼りするだけでも一応画面として表示できる。
const App = () => {
  const theme = createTheme({
    typography: {
      // fontFamily: "a-otf-ud-shin-maru-go-pr6n",
      fontFamily: "A-OTF-UDShinGoNTPro-Medium",
      button: {
        textTransform: "none",
      },
    },
  });

  return (
    <ThemeProvider theme={theme}>
      {/* react-router-domを使いwebページのルーティングを行う */}
      <AuthProvider>
        <DndProvider
          backend={TouchBackend}
          options={{ enableMouseEvents: true, enableTouchEvents: true }}
        >
          <Router>
            <Routes>
              <Route path="/" element={<Login />} />
              <Route path="/callback/:mode" element={<Callback />} />
              <Route path="/:userId/:num" element={<Home />} />
              <Route path="/:userId" element={<Home />} />
            </Routes>
          </Router>
        </DndProvider>
      </AuthProvider>
    </ThemeProvider>
  );
};

export default App;
