// 棚サンプル
// スプシからデータを取得する処理の参考リンク
// https://sonnamonyaro.hatenablog.com/entry/2020/03/01/222650
// https://github.com/theoephraim/node-google-spreadsheet
import React, { useCallback, useState, useEffect, Fragment } from "react";
import AddContentDialog from "../parts/AddContentDialog";
import Header from "../parts/Header";
import ConfirmDialog from "../parts/ConfirmDialog";
import PopUpDialog from "../parts/PopUpDialog";
import UserSettingDialog from "../parts/UserSettingDialog";
import ProgressRing from "../parts/ProgressRing";
import ContentItem from "../parts/ContentItem";
import deleteIcon from "../../resources/images/delete_content_list.png";
import arrowLeft from "../../resources/images/arrow_l.png";
import arrowRight from "../../resources/images/arrow_r.png";
import addContentIcon from "../../resources/images/add_content.png";
import addContentActiveIcon from "../../resources/images/add_content_active.png";
import defaultAvatar from "../../resources/images/default_avatar.svg";
import editIcon from "../../resources/images/edit.png";
import editActiveIcon from "../../resources/images/edit_active.png";
import shareIcon from "../../resources/images/share_icon.png";
import twitterLogo from "../../resources/images/twitter_logo.png";
import randomIcon from "../../resources/images/random_icon.png";
import dot from "../../resources/images/dot.png";
import dotActive from "../../resources/images/dot_active.png";
import wave from "../../resources/images/wave.png";
import waveMobile from "../../resources/images/wave_mobile.png";
import {
  CONSOLE_METHOD,
  DEFAULT_MAX_TAB_NUM,
  DIALOG_MODE,
  EMPTY_CONTENT_LIST,
  STANDARD_WIDTH,
  TABLE_NAME,
} from "../../resources/constants";
import homeStyles from "../../styles/home.module.scss";
import commonStyles from "../../styles/common.module.scss";
import { useParams } from "react-router-dom";
import { useAuth } from "../../context/auth";
import {
  consoleMethod,
  escapeInput,
  isEqual,
  onEnterKey,
  unescapeString,
  urlWithTrackingId,
  tanaNameValidator,
} from "../../utils/commonUtil";
import { useNavigate } from "react-router-dom";
import {
  BUTTON_TEXT,
  ERROR_MESSAGES,
  SUCCESS_MESSAGES,
  TOOLTIPS,
} from "../../resources/messages";
import useMediaQuery from "@mui/material/useMediaQuery";
import {
  collection,
  doc,
  setDoc,
  getDoc,
  getDocs,
  deleteDoc,
} from "firebase/firestore";
import { auth, db } from "../../config/firebase";
import update from "immutability-helper";
import Tooltip from "@mui/material/Tooltip";
import lodash from "lodash";
import { useSwipeable } from "react-swipeable";

const Home = () => {
  // URLから取得したユーザID
  // 変数名は、App.jsのRouteで指定した変数名（:userId）と同じにする必要がある
  const { userId, num } = useParams();
  const contentListNum = isNaN(num) ? 1 : parseInt(num);
  const navigate = useNavigate();
  // ログイン時に取得した認証情報
  const { authInfo } = useAuth();
  const isStandardWidth = useMediaQuery(STANDARD_WIDTH);
  const [userInfo, setUserInfo] = useState({});
  // ユーザが持つ全部のリストのデータが入っている
  const [listData, setListData] = useState([]);
  // 変更前のリストデータデータをstring化して格納
  const [oldData, setOldData] = useState({});
  // 棚に表示するリスト1つ分の情報が入っている
  const [contentList, setContentList] = useState([]);
  // 編集するコンテンツのインデックス（0始まりの番号）
  const [contentIndex, setContentIndex] = useState(0);
  const [dialogMode, setDialogMode] = useState("");
  const [isEdit, setIsEdit] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isImageLoaded, setImageLoaded] = useState(false);
  // 現在選択しているタブのインデックス（0始まりの番号）
  const [currentTabIndex, setCurrentTabIndex] = useState(contentListNum - 1);
  const [tabList, setTabList] = useState([]);
  const [tabNameInput, setTabNameInput] = useState("");
  // const [errorMessage, setErrorMessage] = useState("");
  const [isAuth, setIsAuth] = useState(false);
  // アカウント削除フラグ
  const [isDeleted, setIsDeleted] = useState(false);

  const handleOpenDialog = (dialogMode, contentIndex) => {
    setDialogMode(dialogMode);
    setContentIndex(contentIndex ? contentIndex : 0);
  };

  const handleCloseDialog = () => {
    setContentIndex(0);
    setDialogMode("");
  };

  const handleAddTab = () => {
    // タブ追加時にリストの末尾+1をインデックスに設定することで、自動的に新しいタブが選択されるようにする
    const targetContentListIndex = tabList.length;
    navigate(`/${userId}/${targetContentListIndex + 1}`);
    setCurrentTabIndex(targetContentListIndex);
    setIsEdit(true);
  };

  const handleChangeContentsData = (pageLink, imageLink) => {
    const newListData = listData;
    newListData[tabList[currentTabIndex]].content_list[
      contentIndex
    ].content_page_link = pageLink;
    newListData[tabList[currentTabIndex]].content_list[
      contentIndex
    ].content_image_link = imageLink;
    // ユーザのTANAの全データが入っているstateを更新する
    // contentListを更新すると、タブ遷移したときとかに変更が消えるため
    setListData(newListData);
  };

  const handleChangeTabName = () => {
    if (!tabNameInput) { // 棚作成時だとtabNameInput空なので潰す
      return;
    } else if (tabNameInput === tabList[currentTabIndex]) { // 名前を変更していない場合は何もしない
      return;
    } else if (tabList.includes(tabNameInput)) { // 同じ名前の棚があればユーザプロンプト出す
      alert(ERROR_MESSAGES.CATEGORY_ALREADY_EXISTS);
      return;
    }
    
    // TANA名を表すプロパティを変更
    // 新しいプロパティ（tabNameInput）の値として前のプロパティの値を代入
    listData[escapeInput(tabNameInput)] = listData[tabList[currentTabIndex]];
    // 前のプロパティを削除
    delete listData[tabList[currentTabIndex]];
    const tabs = Object.keys(listData).sort((a, b) => {
      return listData[a].created_at < listData[b].created_at ? -1 : 1;
    });
    // タブリストのstateを更新する
    setTabList(tabs);
  };

  const handleSubmitEdit = () => {
    try {
      setIsLoading(true);
      handleChangeTabName();
      const isUpdated = !isEqual(oldData, listData);
      if (isUpdated) {
        const contentsDocumentRef = doc(db, TABLE_NAME.CONTENTS, userId);
        setDoc(contentsDocumentRef, listData);
        // 変更後の値でoldDataを更新する
        const oldData = JSON.parse(JSON.stringify(listData));
        setOldData(oldData);
      }
      setIsEdit(false);
    } catch (error) {
      consoleMethod(error, CONSOLE_METHOD.ERROR);
      alert(ERROR_MESSAGES.SYSTEM_ERROR);
    } finally {
      setIsLoading(false);
    }
  };

  const handleEditMode = async () => {
    if (tabNameInput && !tanaNameValidator(tabNameInput)){
      alert(ERROR_MESSAGES.COUNT_OVER_TITLE_LENGTH);
      return;
    }
    setIsEdit(!isEdit);
    if (isEdit) {
      // 編集モードからもとに戻る場合、変更を保存する
      handleSubmitEdit();
    } else if (!isEdit){
      // 通常モードから編集モードにする場合、既存の棚名をインプットのデフォルト値にする
      setTabNameInput(tabList[currentTabIndex]);
    }
  };

  // ランダムな棚に遷移する処理
  const handleClickRandom = async () => {
    // コンテンツリスト一覧取得
    const contentsCollectionRef = collection(db, TABLE_NAME.CONTENTS);
    const contentsDocs = await getDocs(contentsCollectionRef);
    const userIdList = [];
    // ログインしているユーザと、棚を作っていないユーザのID以外をリストに追加する
    contentsDocs.forEach((doc) => {
      if (
        doc.id !== authInfo?.screenName &&
        Object.keys(doc.data()).length !== 0 &&
        !lodash.isEqual(
          Object.values(doc.data())[0].content_list,
          EMPTY_CONTENT_LIST
        )
      ) {
        userIdList.push(doc.id);
      }
    });

    // ユーザIDリストの中から存在しないユーザを取り除く
    const userCollectionRef = collection(db, TABLE_NAME.USERS);
    const userDocs = await getDocs(userCollectionRef);
    const existUserList = [];
    userDocs.forEach((doc) => existUserList.push(doc.id));
    userIdList.forEach((id) => {
      if (!existUserList.includes(id)) {
        const index = userIdList.indexOf(id);
        userIdList.splice(index, 1);
      }
    });

    // リストからランダムにIDを選択する
    const targetId = userIdList[Math.floor(Math.random() * userIdList.length)];
    // 他の棚に遷移した時、タブの位置を一番最初に戻す
    setCurrentTabIndex(0);
    // 棚がない人のページに飛んだ場合のために、棚の中身をリセットする
    setListData([]);
    // ランダムに選ばれた人の棚に遷移する
    if (targetId) {
      navigate(`/${targetId}/1`);
    }
  };

  // ユーザのTANA全データとタブリストを取得
  const getListData = useCallback(async () => {
    try {
      const contentsDocumentRef = doc(db, TABLE_NAME.CONTENTS, userId);
      const contentsDoc = await getDoc(contentsDocumentRef);
      const listData = contentsDoc?.data();

      // TANAデータからタブ名のみを抽出
      const tabs = Object.keys(listData).sort((a, b) => {
        return listData[a].created_at < listData[b].created_at ? -1 : 1;
      });

      // 以下の場合は最初の棚があるページに飛ばす
      // URLに棚の番号がなかった場合
      // URL末尾に文字列を入れられた場合
      // URL末尾に棚の数より多い数や1未満の数を入れられたとき
      if (
        !num ||
        isNaN(num) ||
        parseInt(num) > tabs.length ||
        parseInt(num) < 1
      ) {
        setCurrentTabIndex(0);
        navigate(`/${userId}/1`);
      }

      setListData(listData);
      // ここで、変更前データを退避させるためにlistDataの値渡しを行う
      const oldData = JSON.parse(JSON.stringify(listData));
      setOldData(oldData);
      setTabList(tabs);
      // コンテンツリストの初回読み込みを行う
      const targetContentList =
        listData[tabs[currentTabIndex]]?.content_list || [];
      // コンテンツリストが空ならそのまま登録、中身があるなら画像をプリロードしてから登録
      if (lodash.isEqual(targetContentList, EMPTY_CONTENT_LIST)) {
        setContentList(targetContentList);
      } else {
        await Promise.allSettled(loadImages(targetContentList)).then(() => {
          setContentList(targetContentList);
        });
      }
    } catch (error) {
      // todo シノペ アラートダイアログを実装する
      consoleMethod(error, CONSOLE_METHOD.ERROR);
      if (error.code === "429" || error.code === 429) {
        alert(ERROR_MESSAGES.TOO_MANY_REQUESTS);
        // setErrorMessage(ERROR_MESSAGES.TOO_MANY_REQUESTS);
      } else {
        alert(ERROR_MESSAGES.SYSTEM_ERROR);
        // setErrorMessage(ERROR_MESSAGES.SYSTEM_ERROR);
      }
    }
  }, [num, userId, currentTabIndex, navigate]);

  const getUserInfo = useCallback(async () => {
    try {
      const userDocumentRef = doc(db, TABLE_NAME.USERS, userId);
      const userDoc = await getDoc(userDocumentRef);
      const userInfo = userDoc.data();
      if (!userInfo) {
        throw new Error(ERROR_MESSAGES.USER_NOT_FOUND);
      }
      setUserInfo(userInfo);
    } catch (error) {
      // todo シノペ アラートダイアログを実装する
      // できれば例外処理を一本化したい
      alert(error.message);
      // setErrorMessage(error.message);
      consoleMethod(error, "error");
      throw error;
    }
    // 空のクリーンアップ関数を入れることで、ユーザ削除時にユーザ取得処理が走らないようにする
    return () => {};
  }, [userId]);

  const handleDeleteAccount = async () => {
    try {
      const userDocumentRef = doc(db, TABLE_NAME.USERS, userId);
      const contentsDocumentRef = doc(db, TABLE_NAME.CONTENTS, userId);
      await deleteDoc(contentsDocumentRef);
      await deleteDoc(userDocumentRef);
      // todo シノペ ユーザ削除の時、リダイレクトで再度Twitterログインをさせてから削除を行う。Callbackページを使って実装する
      // await deleteUser(user);
      setIsDeleted(true);
      alert(SUCCESS_MESSAGES.DELETE_ACCOUNT);
    } catch (error) {
      consoleMethod(error, CONSOLE_METHOD.ERROR);
      // Firebase Authの仕様上、ログインしてから5分以上経過するとアカウント削除等の際に再ログインが求められる
      // そのため、再ログインしてからアカウント削除をするよう促す
      if (error.code === "auth/requires-recent-login") {
        alert(ERROR_MESSAGES.REQUIRES_RECENT_LOGIN);
      } else {
        alert(ERROR_MESSAGES.SYSTEM_ERROR_FOR_DELETE_ACCOUNT);
      }
    } finally {
      await auth.signOut();
      navigate("/");
    }
  };

  const handleClickAppLogo = () => {
    // 認証情報があればログイン中のユーザIDをもとにユーザページに遷移する
    // 認証情報がなければ、loginIdはnullなのでログインページに遷移する
    const loginId = authInfo?.screenName;
    setCurrentTabIndex(0);
    navigate(loginId ? `/${loginId}/1` : "/");
  };

  const handleDeleteAction = async (dialogMode) => {
    try {
      setIsLoading(true);
      handleCloseDialog();
      switch (dialogMode) {
        case DIALOG_MODE.DELETE_ACCOUNT:
          await handleDeleteAccount();
          break;
        case DIALOG_MODE.DELETE_CONTENT:
          // ここでstateを更新して見た目を最新化する
          const newListData = listData;
          newListData[tabList[currentTabIndex]].content_list[
            contentIndex
          ].content_page_link = "";
          newListData[tabList[currentTabIndex]].content_list[
            contentIndex
          ].content_image_link = "";
          // ユーザのTANAの全データが入っているstateを更新する
          // contentListを更新すると、タブ遷移したときとかに変更が消えるためContentsDataに格納する
          setListData(newListData);
          break;
        case DIALOG_MODE.DELETE_CONTENT_LIST:
          delete listData[tabList[currentTabIndex]];
          const tabs = Object.keys(listData).sort((a, b) => {
            return listData[a].created_at < listData[b].created_at ? -1 : 1;
          });
          // タブリストのstateを更新する
          setTabList(tabs);
          setCurrentTabIndex(0);
          // 削除した結果棚の数が0になった場合、棚の中身を空にする
          if (tabs.length === 0) {
            setContentList([]);
            setIsLoading(false);
          }
          setIsEdit(false);
          const contentsDocumentRef = doc(db, TABLE_NAME.CONTENTS, userId);
          await setDoc(contentsDocumentRef, listData);
          setTabNameInput(tabList[0]);
          break;
        default:
          break;
      }
      if (dialogMode === DIALOG_MODE.DELETE_ACCOUNT) {
        navigate("/");
      }
    } catch (error) {
      consoleMethod(error, CONSOLE_METHOD.ERROR);
      alert(ERROR_MESSAGES.SYSTEM_ERROR);
      // setErrorMessage(ERROR_MESSAGES.SYSTEM_ERROR);
    } finally {
      if (dialogMode !== DIALOG_MODE.DELETE_CONTENT_LIST) {
        setIsLoading(false);
      }
    }
  };

  // 画像のプリロード用関数
  const loadImages = (contentList) => {
    return contentList.map((content) => {
      return new Promise((resolve, reject) => {
        const img = new Image();
        img.onload = () => resolve(img);
        img.onerror = () => reject(img);
        img.src = urlWithTrackingId(content.content_image_link);
      });
    });
  };

  // ドラッグアンドドロップ用関数
  const moveContent = useCallback(
    (dragIndex, hoverIndex) => {
      setContentList((contentList) =>
        update(contentList, {
          $splice: [
            // ドラッグ元の要素を削除して、代わりにドロップ先の要素を代入
            [dragIndex, 1, contentList[hoverIndex]],
            // ドロップ先の要素を削除して、代わりにドラッグ元の要素を代入
            [hoverIndex, 1, contentList[dragIndex]],
          ],
        })
      );
      // コンテンツリストを更新した後、リストデータを更新する
      // ここはcontentListを更新しているように見えるが、ただ最新のcontentListを取得してlistDataを更新しているだけ
      // 参考:https://zenn.dev/syu/articles/3c4aa813b57b8c#2.%E9%96%A2%E6%95%B0%E5%9E%8B%E3%81%AE%E6%9B%B4%E6%96%B0%E3%82%92%E4%BD%BF%E3%81%86
      setContentList((contentList) => {
        const newListData = listData;
        newListData[tabList[currentTabIndex]].content_list = contentList;
        setListData(newListData);
        return contentList;
      });
    },
    [listData, tabList, currentTabIndex]
  );

  const swipeHandlers = useSwipeable({
    onSwipedRight: () => {
      if (currentTabIndex > 0) {
        navigate(`/${userId}/${contentListNum - 1}`);
        setTabNameInput(tabList[currentTabIndex - 1]);
        setCurrentTabIndex(currentTabIndex - 1);
      }
    },
    onSwipedLeft: () => {
      if (tabList[currentTabIndex] !== tabList.slice(-1)[0]){
        navigate(`/${userId}/${contentListNum + 1}`);
        setTabNameInput(tabList[currentTabIndex + 1]);
        setCurrentTabIndex(currentTabIndex + 1);
      }
    },
    ...{
      delta: 15,
      preventScrollOnSwipe: false,
      trackTouch: true,
      trackMouse: true,
      rotationAngle: 0,
      swipeDuration: 3000,
      touchEventOptions: { passive: true },
    },
  });

  useEffect(() => {
    (async () => {
      // アカウントが削除されていたら、初期処理を行わない
      if (isDeleted) {
        return;
      }
      try {
        setIsLoading(true);
        await getUserInfo();
        await getListData();
        setIsLoading(false);
      } catch (error) {
        if (error.message === ERROR_MESSAGES.USER_NOT_FOUND) navigate("/");
      }
    })();
  }, [isDeleted, getUserInfo, getListData, navigate]);

  useEffect(() => {
    // listDataが存在するときのみに下記処理を動かす
    if (Object.keys(listData).length > 0) {
      setIsLoading(true);
      const targetContentList =
        listData[tabList[currentTabIndex]]?.content_list || [];
      // 画像のプリロードを行ってからstateの更新を行う
      Promise.allSettled(loadImages(targetContentList)).then(() => {
        setContentList(targetContentList);
        // コンテンツリストが読み込まれていない状態では、プログレスリングが表示されるようにする
        // このuseEffectは上のuseEffectより早く読み込まれるため、ここで無条件に解除するとロード中にプログレスリングが外れてしまう
        // それを避けるため、コンテンツリストが読み込まれていなければロード中とし、上のuseEffectでロードが終わったらプログレスリングが外れるようになっている
        targetContentList.length > 0 ? setIsLoading(false) : setIsLoading(true);
      });
    }
  }, [currentTabIndex, tabList, listData]);

  // URLのユーザIDと認証情報のIDが同じ場合にのみ認証済みフラグを立てる
  // 現状はTwitterログインのみのため、Twitterのスクリーンネーム（@以降の英数字）をIDとして使用している
  // ログインの形式を変更する場合認証周りを修正する必要あり
  // todo シノペ screenNameだとなんのこっちゃわからないからloginIdとかに名前を変える
  useEffect(() => {
    setIsAuth(userId === authInfo?.screenName ? true : false);
  }, [userId, authInfo]);

  return (
    <div className={isStandardWidth ? homeStyles.home : homeStyles.homeNarrow}>
      <Header
        isAuth={isAuth}
        isStandardWidth={isStandardWidth}
        handleOpenDialog={handleOpenDialog}
        handleClickAppLogo={handleClickAppLogo}
      />

      <div
        className={
          isStandardWidth ? homeStyles.userInfo : homeStyles.userInfoMobile
        }
      >
        <img
          className={isStandardWidth ? homeStyles.wave : homeStyles.waveMobile}
          src={isStandardWidth ? wave : waveMobile}
          alt=""
        />
        <a
            href={userInfo.twitter_link}
            target="_blank"
            rel="noopener noreferrer"
        >
          <img
            className={homeStyles.avatar}
            // todo シノペ デフォルトアバターは仮置きのため、デザインが決まり次第入れ替える
            src={userInfo?.avatar ? userInfo.avatar : defaultAvatar}
            alt=""
            // 画像のリンクが切れていた場合、デフォルトの画像を表示する
            onError={(e) => {
              e.currentTarget.src = defaultAvatar;
            }}
          />
          <span className={homeStyles.userName}>{userInfo.name}</span>
          <div className={homeStyles.twitterId}>
            
              <img
                className={commonStyles.twitterLogo}
                src={twitterLogo}
                alt="twitter"
              />
              <span>{`@${userId}`}</span>
          </div>
        </a>
        <div
          className={
            isEdit ? homeStyles.socialMenuHidden : homeStyles.socialMenu
          }
        >
          <Tooltip title={TOOLTIPS.SHARE}>
            <input
              type="image"
              className={
                isStandardWidth
                  ? commonStyles.iconButton
                  : commonStyles.iconButtonMobile
              }
              src={shareIcon}
              alt={"シェア"}
              onClick={() => handleOpenDialog(DIALOG_MODE.SHARE)}
            />
          </Tooltip>
          <Tooltip title={TOOLTIPS.RANDOM}>
            <input
              type="image"
              className={
                isStandardWidth
                  ? commonStyles.iconButton
                  : commonStyles.iconButtonMobile
              }
              src={randomIcon}
              alt=""
              onClick={handleClickRandom}
            />
          </Tooltip>
        </div>
      </div>

      <div className={homeStyles.tabList}>
        {/* ドットのnavigation */}
        <div className={commonStyles.dotsPagination}>
          {tabList.length > 1 &&
            tabList.map((_, index) => {
              return (
                <img
                  className={commonStyles.dots}
                  src={index === currentTabIndex ? dotActive : dot}
                  key={index}
                  alt=""
                  onClick={() => {
                    navigate(`/${userId}/${index + 1}`);
                    setCurrentTabIndex(index);
                    setTabNameInput(tabList[index]);
                  }}
                />
              );
            })}
        </div>
      </div>
      <div className={homeStyles.contentListMenuBar}>
        <div
          className={
            isAuth && isEdit
              ? homeStyles.contentsMenu
              : homeStyles.contentsMenuHidden
          }
        >
          <Tooltip title={TOOLTIPS.DELETE}>
            <input
              type="image"
              className={
                isStandardWidth
                  ? commonStyles.iconButton
                  : commonStyles.iconButtonMobile
              }
              src={deleteIcon}
              alt="削除"
              onClick={() => handleOpenDialog(DIALOG_MODE.DELETE_CONTENT_LIST)}
            />
          </Tooltip>
        </div>
        {/* 棚のタイトル */}
        <div
          className={
            isStandardWidth
              ? homeStyles.contentListTitle
              : homeStyles.contentListTitleMobile
          }
        >
          {isEdit ? (
            <Fragment>
              <input
                type="text"
                className={homeStyles.titleInputText}
                value={unescapeString(tabNameInput)}
                onChange={(event) => setTabNameInput(event.target.value)}
                onKeyDown={(event) => onEnterKey(event.key, handleChangeTabName)}
              />
              <div style={tabNameInput.length === 0 || tabNameInput.length > 10 ? {"color": "#d02d4d"} : {}}>
                {tabNameInput.length}/10
                </div>
            </Fragment>
          ) : (
            <span>{unescapeString(tabList[currentTabIndex])}</span>
          )}
        </div>
        <div
          className={
            isAuth ? homeStyles.contentsMenu : homeStyles.contentsMenuHidden
          }
        >
        <Tooltip title={TOOLTIPS.ADD}>
          <input
            type="image"
            className={
              (!isEdit && tabList.length > 0 && tabList.length < DEFAULT_MAX_TAB_NUM)
                ? isStandardWidth
                  ? commonStyles.iconButton
                  : commonStyles.iconButtonMobile
                : commonStyles.iconHidden
            }
            src={isEdit ? addContentActiveIcon : addContentIcon}
            alt="追加"
            onClick={() => handleOpenDialog(DIALOG_MODE.ADD_CONTENT_LIST)}
          />
        </Tooltip>
        <Tooltip title={TOOLTIPS.EDIT}>
          <input
            type="image"
            className={
              isStandardWidth
                ? commonStyles.iconButton
                : commonStyles.iconButtonMobile
            }
            src={isEdit ? editActiveIcon : editIcon}
            alt={tabList.length === 0 ? "追加" : "編集"}
            onClick={() =>
              tabList.length === 0
                ? handleOpenDialog(DIALOG_MODE.ADD_CONTENT_LIST)
                : handleEditMode()
            }
          />
        </Tooltip>
        </div>
      </div>

      <div className={homeStyles.contentListCarousel} {...swipeHandlers}>
        <ul
          className={
            isStandardWidth ? homeStyles.tana3x3 : homeStyles.tana3x3Narrow
          }
        >
          {contentList.map((content, index) => {
            return (
              <ContentItem
                key={index}
                index={index}
                data={content}
                isEdit={isEdit}
                moveContent={moveContent}
                handleOpen={() =>
                  handleOpenDialog(DIALOG_MODE.ADD_CONTENT, index)
                }
                clickDeleteContent={() =>
                  handleOpenDialog(DIALOG_MODE.DELETE_CONTENT, index)
                }
                isImageLoaded={isImageLoaded}
                setImageLoaded={setImageLoaded}
              />
            );
          })}
        </ul>
        {currentTabIndex > 0 && (
          <input
            type="image"
            className={commonStyles.arrowLeft}
            src={arrowLeft}
            alt="前の棚"
            onClick={() => {
              navigate(`/${userId}/${contentListNum - 1}`);
              setTabNameInput(tabList[currentTabIndex - 1]);
              setCurrentTabIndex(currentTabIndex - 1);
            }}
          />
        )}
        {tabList[currentTabIndex] !== tabList.slice(-1)[0] && (
          <input
            type="image"
            className={commonStyles.arrowRight}
            src={arrowRight}
            alt="次の棚"
            onClick={() => {
              navigate(`/${userId}/${contentListNum + 1}`);
              setTabNameInput(tabList[currentTabIndex + 1]);
              setCurrentTabIndex(currentTabIndex + 1);
            }}
          />
        )}
      </div>

      {isEdit && (
        <button
          className={
            isStandardWidth
              ? commonStyles.editOkButton
              : commonStyles.editOkButtonMobile
          }
          onClick={handleSubmitEdit}
        >
          {BUTTON_TEXT.SAVE}
        </button>
      )}
      <AddContentDialog
        isOpen={
          dialogMode === DIALOG_MODE.ADD_CONTENT ||
          dialogMode === DIALOG_MODE.ADD_CONTENT_LIST
        }
        addDialogMode={dialogMode}
        userId={userId}
        tabList={tabList}
        handleChangeContentsData={handleChangeContentsData}
        handleAddTab={handleAddTab}
        setTabNameInput={setTabNameInput}
        getListData={getListData}
        handleClose={handleCloseDialog}
        handleSubmitEdit={handleSubmitEdit}
        setIsLoading={setIsLoading}
        isStandardWidth={isStandardWidth}
      />
      <ConfirmDialog
        isOpen={
          dialogMode === DIALOG_MODE.DELETE_ACCOUNT ||
          dialogMode === DIALOG_MODE.DELETE_CONTENT ||
          dialogMode === DIALOG_MODE.DELETE_CONTENT_LIST
        }
        confirmDialogMode={dialogMode}
        targetContent={
          listData[tabList[currentTabIndex]]?.content_list[contentIndex]
            .content_image_link
        }
        handleAction={() => handleDeleteAction(dialogMode)}
        handleClose={handleCloseDialog}
      />
      <PopUpDialog
        isOpen={dialogMode === DIALOG_MODE.SHARE}
        dialogMode={dialogMode}
        isStandardWidth={isStandardWidth}
        userName={userInfo.name}
        handleClose={handleCloseDialog}
      />
      <UserSettingDialog
        isOpen={
          dialogMode === DIALOG_MODE.SETTING_SNS ||
          dialogMode === DIALOG_MODE.SETTING_NAME
        }
        dialogMode={dialogMode}
        userInfo={userInfo}
        handleClose={handleCloseDialog}
        setUserInfo={setUserInfo}
        setIsLoading={setIsLoading}
      />
      <ProgressRing isLoading={isLoading} />
    </div>
  );
};
export default Home;
