// アフィリエイトリンクを表示させるパーツ
import React, { useState, useEffect, useRef } from "react";
import addItemIcon from "../../resources/images/add_item.png";
import deleteItemIcon from "../../resources/images/delete_item.png";
import commonStyles from "../../styles/common.module.scss";
import { urlWithTrackingId } from "../../utils/commonUtil";
import { useDrag, useDrop } from "react-dnd";
import DndPreview from "./DndPreview";

const ContentItem = (props) => {
  const [imageLink, setImageLink] = useState("");

  const ref = useRef(null);

  const [{ handlerId }, drop] = useDrop({
    accept: "content",
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item, monitor) {
      // ドラッグアンドドロップの対象がなかったり、編集モードでない場合は何もしない
      if (!ref.current || !props.isEdit) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = props.index;
      if (dragIndex === hoverIndex) {
        return;
      }

      // ドラッグアンドドロップの範囲（ホバーの範囲はどこまでか）を定義
      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      // どの位置までドラッグすればドロップされるかを定義
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      // マウスポジションを定義
      const clientOffset = monitor.getClientOffset();
      // ドラッグアンドドロップ範囲のトップを定義
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;
      // 指定した位置までドラッグしなければ何もしない
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }
      // stateを更新する
      props.moveContent(dragIndex, hoverIndex);
      // 要素のインデックスを更新後のものに変更
      item.index = hoverIndex;
    },
  });
  const [{ isDragging }, drag] = useDrag({
    type: "content",
    item: () => {
      const id = props.index;
      const index = props.index;
      return { id, index };
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  // ドラッグ中になにかしたい（ログになにか出したい、ドラッグ中のスタイルを変えたい）時に使う
  // 現状は特に何もしていないが、ビルド時に警告が出るのを避けるためこのif文を実装している
  if (isDragging) {
  }

  drag(drop(ref));

  const handleClick = (event) => {
    event.preventDefault();
    props.handleOpen();
  };

  const checkUrl = (url) => {
    const checkResult = url.indexOf("www.amazon.co.jp");
    if (!url) {
      return;
    } else if (checkResult >= 0) {
      // urlがAmazonのものだったら、トラッキングIDをつけて返却する
      return urlWithTrackingId(url);
    } else {
      // todo シノペ こちらのフローに流れてくる場合、外部からイレギュラーなリンクを入れられてる可能性が高いため、ロギングしておく
      return;
    }
  };

  // リンク切れの画像があったらそのコンテンツが削除されたように見せる処理
  // 実際のDBのデータを削除するのではなく画面に表示する画像リンクを空にすることで
  // リンク切れの場所に「商品を登録していない空の棚」を表示させる
  // リンク切れの場所で再度商品を登録し直せばリンク切れの商品が上書きされる仕組み
  useEffect(() => {
    // 前の画像が表示されないよう、stateを一旦リセットする
    setImageLink("");
    // 画像が読み込めたらその画像を、読み込めなかったら空文字をセットする
    if (props.data.content_image_link) {
      let img = new Image();
      img.onload = () => {
        setImageLink(urlWithTrackingId(props.data.content_image_link));
      };
      img.onerror = () => {
        setImageLink("");
      };
      img.src = urlWithTrackingId(props.data.content_image_link);
    }
  }, [props.data.content_image_link]);

  return (
    <li ref={ref} data-handler-id={handlerId}>
      {/* 製品リンクと画像リンク両方ある場合のみアフィリンクを表示し、それ以外は+ボタンを表示する */}
      {/* この書き方は「三項演算子」というものでプログラミングだとよく出てくる */}
      {/* TODO シノペ 画像のリンクが切れていた場合の処理をちゃんと実装する、今のは仮対応 */}
      {props.data.content_page_link && imageLink ? (
        <div className={commonStyles.contentBox}>
          <a
            target="_blank"
            rel="noreferrer"
            href={checkUrl(props.data.content_page_link)}
          >
            <img border="0" src={urlWithTrackingId(imageLink)} alt="" />
            {isDragging && (
              <DndPreview
                src={urlWithTrackingId(props.data.content_image_link)}
                isEdit={props.isEdit}
              />
            )}
          </a>
          {/* 編集モード時のみ、コンテンツの上に削除マークを表示する */}
          {props.isEdit && (
            <div className={commonStyles.editableContent}>
              <input
                type="image"
                className={commonStyles.deleteButton}
                src={deleteItemIcon}
                alt="削除"
                onClick={props.clickDeleteContent}
              />
            </div>
          )}
        </div>
      ) : (
        <div className={commonStyles.contentBox}>
          <a
            target="_blank"
            rel="noreferrer"
            href={urlWithTrackingId(props.data.content_page_link)}
          >
            {props.isEdit && (
              <div className={commonStyles.editableContent}>
                <img
                  className={commonStyles.addButton}
                  border="0"
                  src={addItemIcon}
                  alt=""
                  onClick={(event) => handleClick(event)}
                />
              </div>
            )}
            {isDragging && (
              <DndPreview
                src={urlWithTrackingId(props.data.content_image_link)}
                isEdit={props.isEdit}
              />
            )}
          </a>
        </div>
      )}
    </li>
  );
};

export default ContentItem;
