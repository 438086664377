// メッセージ以外の固定値をここで定義
// 画面に表示しない内部用の固定値を定義

/**
 * テーブル名
 */
export const TABLE_NAME = {
  CONTENTS: "contents",
  USERS: "users",
};

/**
 * Amazon アソシエイトのトラッキングID
 */
export const TRACKING_ID = "skdnfft0702-22";

// todo シノペ 正規表現を1つのオブジェクトにまとめる

/**
 * ASIN取得用の正規表現
 */
export const ASIN_REGEX = /dp\/[a-zA-Z0-9]{10}/g;

/**
 * Prime VideoのASIN取得用の正規表現
 */
export const VIDEO_ASIN_REGEX = /gp\/video\/detail\/[a-zA-Z0-9]{10}/g;

/**
 * MusicのASIN取得用の正規表現
 */
export const MUSIC_ASIN_REGEX = /music\/player\/albums\/[a-zA-Z0-9]{10}/g;

/**
 * SNS ID用の正規表現
 */
export const ALPHANUMERIC_REGEX = /^[a-zA-Z0-9\\_]+$/;

/**
 * Amazonアソシエイトの画像リンク
 */
export const AMAZON_IMAGE_LINK =
  "https://ws-fe.amazon-adsystem.com/widgets/q?ASIN=0000000000&Format=_SL250_&ID=AsinImage&ServiceVersion=20070822&WS=1";

/**
 * firebase functionsのURL
 */
export const FIREBASE_FUNCTIONS_URL = "https://us-central1-tana-dev-164c0.cloudfunctions.net/"
/**
 * URL拡張用のAPI
 */
export const API_URL_EXPAND = FIREBASE_FUNCTIONS_URL + "redirect"
/**
 * URL拡張用のクエリ
 */
export const API_URL_EXPAND_QUERY = "url"

/**
 * ダイアログのモード（表示するダイアログを制御するためのもの）
 */
export const DIALOG_MODE = {
  ADD_CONTENT: "add_content",
  ADD_CONTENT_LIST: "add_content_list",
  DELETE_ACCOUNT: "delete_account",
  DELETE_CONTENT: "delete_content",
  DELETE_CONTENT_LIST: "delete_content_list",
  SHOW_DETAIL: "show_detail",
  ERROR: "error",
  SHARE: "share",
  SETTING_SNS: "setting_sns",
  SETTING_NAME: "setting_name",
  TERMS_OF_USE: "terms_of_use"
};

/**
 * SNSリンクのバリデーション用正規表現
 */
export const SNS_LINK = {
  TWITTER: "https://twitter.com/",
  FACEBOOK: "https://www.facebook.com/",
  INSTAGRAM: "https://www.instagram.com/",
  PINTEREST: "https://www.pinterest.com/",
};

export const TEXT_LENGTH = {
  MIN: 1,
  NAME: 15,
  TAB_NAME: 10,
};

export const CONSOLE_METHOD = {
  LOG: "log",
  ERROR: "error",
};

export const DEFAULT_MAX_TAB_NUM = 15;

export const STANDARD_WIDTH = "(min-width:390px)";

export const APP_MODE = {
  DEVELOPMENT: "development",
  PRODUCTION: "production",
};

export const CONTACT_FORM = "https://forms.gle/qnkyCcmuD7TzeSqw6";

export const EMPTY_CONTENT_LIST = [
  {
    content_image_link: "",
    content_page_link: "",
  },
  {
    content_image_link: "",
    content_page_link: "",
  },
  {
    content_image_link: "",
    content_page_link: "",
  },
  {
    content_image_link: "",
    content_page_link: "",
  },
  {
    content_image_link: "",
    content_page_link: "",
  },
  {
    content_image_link: "",
    content_page_link: "",
  },
  {
    content_image_link: "",
    content_page_link: "",
  },
  {
    content_image_link: "",
    content_page_link: "",
  },
  {
    content_image_link: "",
    content_page_link: "",
  },
];
