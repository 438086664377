import React, { useState, useEffect } from "react";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import { DIALOG_MODE } from "../../resources/constants";
import commonStyles from "../../styles/common.module.scss";
import { BUTTON_TEXT, DIALOG_TITLE } from "../../resources/messages";
import { urlWithTrackingId } from "../../utils/commonUtil";

// todo シノペ propsの中身わかりにくい問題に対処する（優先度低）
const ConfirmDialog = (props) => {
  const [message, setMessage] = useState("");

  const handleClose = () => {
    props.handleClose();
  };

  useEffect(() => {
    switch (props.confirmDialogMode) {
      case DIALOG_MODE.DELETE_CONTENT:
        setMessage(DIALOG_TITLE.DELETE_CONTENT);
        break;
      case DIALOG_MODE.DELETE_CONTENT_LIST:
        setMessage(DIALOG_TITLE.DELETE_CONTENT_LIST);
        break;
      case DIALOG_MODE.DELETE_ACCOUNT:
        setMessage(DIALOG_TITLE.DELETE_ACCOUNT);
        break;
      default:
        break;
    }
  }, [props.confirmDialogMode]);

  return (
    <Dialog
      className={commonStyles.modalDialog}
      open={props.isOpen}
      onClose={handleClose}
      // ダイアログのボックス（白いハコ）のスタイルを上書きするためこのように書いている
      PaperProps={{
        className: commonStyles.dialogPaper,
      }}
    >
      <DialogTitle className={commonStyles.dialogTitle}>{message}</DialogTitle>
      <DialogContent className={commonStyles.confirmDialog}>
        {props.confirmDialogMode === DIALOG_MODE.DELETE_CONTENT && (
          <img
            className=""
            src={urlWithTrackingId(props.targetContent)}
            alt=""
          />
        )}
      </DialogContent>
      <DialogActions className={commonStyles.dialogActions}>
        <button
          className={commonStyles.cancelButton}
          onClick={props.handleClose}
        >
          {BUTTON_TEXT.CANCEL}
        </button>
        <button
          className={commonStyles.actionButton}
          onClick={props.handleAction}
        >
          {BUTTON_TEXT.DELETE}
        </button>
      </DialogActions>
    </Dialog>
  );
};
export default ConfirmDialog;
